import React,{useState, useEffect} from 'react'
import Footer from './components/footer/footer';
import Header from './components/header/header.jsx';
import Servicios from './components/servicios/servicios';
import Main from './components/main/main';
import Contactos from './components/contactos/contactos';
import Expertos from './components/expertos/expertos';

export default function App() {
  const [actualPage, setActualPage] = useState(0);
  const [lang, setLang] = useState('es')
  const [cont, setCont] = useState({
    "h":[//header
      "Inicio", "Servicios", "Expertos", "Contactos", "Iniciar sesión"
    ],
    "f":[//footer
      "Somos una organización profesional de Auditores y Consultores con una visión diferente de la auditoria tradicional y la asesoría de negocios",
      "Contáctate con nosotros",
      "© DERECHOS DE AUTOR 2021. TODOS LOS DERECHOS RESERVADOS",
      "Diseñado y desarrollado por "
    ],
    "b":{//body general
      "m":{//contenido main
        "b":[//banner
          "¡Generamos valor a nuestros clientes!",
          "Conoce más",
          "Más de 10 años de presencia en el mercado salvadoreño",
          "Contáctanos",
          "Visitanos, será un gusto atenderle"
        ],
        "c":[//clientes
          "Total de Clientes","Áreas profesionales","Principales Clientes"
        ]
      },
      "s":[//servicios
        "Servicios",{
          "h":"Auditorías",
          "t":"Las diferentes auditorías, confirman el cumplimiento de normas, principios y políticas financieras, fiscales y contables establecidas por la administración de cada entidad, para el registro de las transacciones. Nuestra legislación establece fechas límites para la presentación de estados financieros e informes de auditores independientes, los cuales deben estar debidamente inscritos y autorizados por las entidades reguladoras del Estado",
          "l":[
            "Auditoría de estados financieros consolidados",
            "Auditoría de procedimientos previamente convenidos",
            "Auditoría interna",
            "Auditoría de cumplimiento",
            "Auditoría operativas y de gestión",
            ["Auditorías de compra - venta de empresas","(due diligence)"],
            "Auditorías forenses",
            "Auditoría de sistemas"
          ]
        },{
          "t":"Mantener la comunicación constante con nuestros clientes es una de nuestras fortalezas, ya que nos permite responder de forma inmediata ante los cambios en las políticas fiscales del Estado, que pueden generar perdidas o inconvenientes a nuestros clientes",
          "l":[
            "Revisiones de cumplimientos fiscales",
            "Asistencia en litigios fiscales o notificaciones fiscales. ",
            "Elaboración de declaraciones juradas de impuestos. ",
            "Asesoramientos residentes extranjeros en El Salvador. ",
            "Asesoramiento en la preparación de solicitudes dirigidas al Ministerio de Hacienda",
            "Asistencia legal en lo comercial, laboral, administrativo y notariado",
            "Constitución de sociedades y ONG´s"
          ]
        },{
          "t":"La externalización de servicios es una práctica habitual en el mundo empresarial que, en plena era digital (industria 4.0), se ha convertido en imprescindible debido a la diversidad de tareas, facilidad de comunicación y confidencialidad de la información. Es por ello que externalizar dichos servicios a profesionales especializados se ha convertido en la base del éxito de la gestión de los recursos y optimización de los procesos empresariales",
          "l":[
            "Outsourcing contable y presentación de declaraciones fiscales",
            "Asistencia en elaboración e implementación de sistemas contables",
            "Administración de Planilla o Nomina",
            "Outsourcing de Auditoria Interna"
          ]
        },{
          "h":"Consultoría",
          "t":"Los constantes cambios en el entorno empresarial y la evolución y crecimientos de nuestros clientes, requieren de mejores prácticas en la implementación de nuevos proyectos o cambios a la estructura",
          "l":[
            "Diseño y estructuración de nuevas sociedades y ONG´s",
            "Reestructuración empresarial (Fusión y liquidación de empresas)",
            "Alianzas estratégicas con otras entidades - joint Venture",
            "Diseño de asocios públicos y privados",
            "Diseños de procesos administrativos y financieros",
            "Evaluación de proyectos de inversión",
            "Valuación de empresas",
            "Solución de sistemas informáticos",
            "Implementación de tableros de control",
            "Capacitación en el área financiera, fiscal y control interno",
            "Acompañamiento e implementación de NIIF´s completas y NIIF para PYMES"
          ]
        },{
          "h":"Estudio de Precios de Transferencia",
          "t":"El código tributario establece cuales son las entidades obligadas a realizar un estudio de precios de transferencia, por lo que hemos hecho una alianza estratégica con una oficina internacional, la cual emitirá dicho estudio con todas las especificaciones requeridas por las autoridades salvadoreñas"
        }
      ],
      "e":[//expertos
        "Expertos",
        "Somos una organización profesional de Auditores y Consultores con una visión diferente de la auditoria tradicional y la asesoría de negocios, con más de 10 años de presencia en el mercado salvadoreño y cuya razón social es Auditoría Integral y Consultoría, S.A. de C.V. que se abrevia AUDICCA, S.A de C.V",
        "¡Experiencia Garantizada!",
        "Somos la mejor opción para las empresas en Centroamérica que requieren servicios de calidad a costos razonables. La fortaleza de nuestra Firma, descansa en la experiencia de nuestro personal, adquirida en Firmas Multinacionales de Auditoría y por trabajos realizados en empresas nacionales e internacionales, del sector público, privado y organizaciones sin fines de lucro",
        "Socio Director",
        "Mas de 25 años de experiencia en auditoría, consultoría y áreas financieras, por trabajos realizados en empresas del sector privado y entidades del sector público. Posee estudios en contaduría pública, auditoría, finanzas, impuestos y áreas especializadas. Como socio director de nuestra Firma, es el encargado de coordinar y controlar los trabajos realizados en nuestros clientes. Además, por su amplia experiencia ha participado como miembro externo de diversas juntas directivas.",
        "Gerente de Auditoría",
        "Mas de 6 años de experiencia en auditoría, consultoría y áreas financieras, por trabajos realizados en empresas del sector privado y entidades del sector público. Posee estudios en contaduría pública, auditoría, finanzas, impuestos y áreas especializadas. ",
        "Alcance de nuestro servicio",
        "En el año 2009 un grupo de profesionales, con una visión contemporánea de la auditoría y la consultoría, toma la decisión de formar AUDICCA, una Firma de altos estándares de calidad y al alcance de las empresas a nivel nacional e internacional",
        "Nuestros socios directores, fundamentan su experiencia por haber trabajado en dos de las principales firmas de auditoría a nivel mundial, por lo que, el trabajo que desarrollamos lo hacemos aplicando las mejores prácticas y las más recientes herramientas tecnológicas como la industria 4.0",
        "Misión",
        "Proporcionar servicios profesionales de clase mundial, que combinen conocimientos, experiencia, tecnología y creatividad en beneficio de nuestros clientes, cumpliendo a la vez con la legislación y normativa nacional e internacional",
        "Visión",
        "Ser reconocidos como una Firma de Auditoría y Consultoría regional, con altos estándares de calidad y ética profesional, generando la confianza de nuestros clientes y el desarrollo profesional de nuestros compañeros de trabajo",
        "Valores",
        [
          "Innovación",
          "Creatividad",
          "Integridad",
          "Honestidad",
          "Profesionalismo"
        ],
        "Socio de Area Legal",//Lic Mauricio
        "Mas de 17 años de experiencia en el area jurídica, y teniendo una amplia experiencia laboral dentro de su ambito, siendo Jefe del Departamento de Catastro de la Dirección de Asuntos Jurídicos del Ministerio de Defensa, fungiendo como Docente de la facultad de Derecho en la Universidad Modular Abierta, ahora siendo nuestro socio y asesor Jurídico.",
        "",
        "",
        "",
        "",
        "",
      ],
      "c":[//contacto
        "Mantente en contacto con nosotros",
        "¿Tienes preguntas sobre cómo AUDICCA puede ayudar a tu empresa?",
        "Envíanos un correo electrónico y nos pondremos en contacto en breve",
        "Dirección",
        "Avenida y Colonia",
        "Teléfono y Correo",
        "Horarios",
        "Lunes a Viernes",
        "Sabados",
        "Envíanos un mensaje",
        "Nombre","Correo","Empresa","Contacto","Mensaje (opcional)","Campo Obligatorio","Enviar"
      ]
    }
  })

  // console.log(JSON.stringify(cont))
  
  const live = p =>{
    sessionStorage.setItem("p",p)
    setActualPage(p)
  }

  function translate(){
    fetch("https://deep-translate1.p.rapidapi.com/language/translate/v2", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "x-rapidapi-key": "f6bd7118aemsh6e2ec36a8c5f161p1ec75fjsn000f6f8cba10",
        "x-rapidapi-host": "deep-translate1.p.rapidapi.com"
      },
      body: JSON.stringify({
        "q": JSON.stringify(cont),
        "source": lang==="es"?'es':'en',
        "target": lang==="es"?'en':'es'
      })
    }).then(res=>res.json())
    .catch(err=>{
      console.log('error')
      console.log(err)
    })
    .then(res => {
      let content = res.data.translations.translatedText.replace(/(\"\s)|(\s\")/g,'"');
      content = content.replace(/(\"\.)/g,"")
      console.log(content)
      let newLang = lang==='es'? 'en':'es';
      sessionStorage.setItem("content",content)
      sessionStorage.setItem("lang",newLang)
      setLang(newLang)
      setCont(JSON.parse(content))
    })
  }

  useEffect(()=>{
    let sessionState = {
      p: sessionStorage.getItem("p"),
      lang: sessionStorage.getItem("lang"),
      cont: sessionStorage.getItem("content")
    };
    if(sessionState.p) setActualPage(parseInt(sessionState.p));
    if(sessionState.lang) setLang(sessionState.lang)
    if(sessionState.cont) setCont(JSON.parse(sessionState.cont))
  },[])

  return (
    <>
      <Header switch={live} page={actualPage} cont={cont.h} lang={[lang,translate]}/>
      <Body switch={live} page={actualPage} cont={cont.b}/>
      <Footer cont={cont.f}/>
    </>
  );
}

function Body(props){
  
  switch (props.page) {
    case 0:   return <Main cont={props.cont.m} switch={props.switch}/>;
    case 1:   return <Servicios cont={props.cont.s}/>;
    case 2:   return <Expertos cont={props.cont.e}/>;
    case 3:   return <Contactos cont={props.cont.c}/>;
    default:  return <Main cont={props.cont.m} switch={props.switch}/>;
  }
}