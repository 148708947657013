import React from 'react'
import auditoria from '../img/auditoria.png'

export default function Auditoria({cont}){
  return(
    <>
    <div className="row">
      <div className="col s12">
        <h4 > {cont.h} & Assurance </h4>
        <div className="divider main-color-4"></div>
        <p style={{textAlign:'justify'}}>{cont.t}</p>
      </div>
      <div className="col l4 hide-on-med-and-down h-100 valign-wrapper">
        <img style={{height:'300px'}}className="responsive-img" src={auditoria} alt="Auditoria"/>
      </div>
      <div className="col s10 offset-s1 l8 ">
        <ul>
          {cont.l.map((e,i)=>{
            return <li key={i} className="valign-wrapper"><i className="material-icons">chevron_right</i>{Array.isArray(e)?<>{e[0]}<br/>{e[1]}</>:e}</li>
          })}
        </ul>
      </div>
    </div>
    </>
  )
}