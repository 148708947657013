import React from 'react'
import Banner from './comp/banner/banner'
import Clientes from './comp/clientes/clientes'

export default function Main(props){
  return(
    <>
    <Banner switch={props.switch} cont={props.cont.b}/>
    <Clientes cont={props.cont.c}/>
    </>
  )
}