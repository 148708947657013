import {useEffect/*, useState*/} from 'react'
import LogoA from './logoA.png'
import aso from './img/aso.png';
import esna from './img/esna.png';
import educo from './img/educo.png';
import gs1 from './img/gs1.png';
import briee from './img/briee.png';
import luissa from './img/luissa.png';
import jacobs from './img/jacobs.png';
import llaollao from './img/llaollao.png';
import mudisa from './img/mudisa.png';
import safety from './img/safety.png';
import sites from './img/sites.png';
import smurfit from './img/smurfit.png';
import subway from './img/subway.png';
import aeinfo from './img/aeinfo.png'
import lacasita from './img/lacasita.png'

import M from 'materialize-css';

const empresas =[
  { alt: 'mudisa',  img: mudisa,  url:'https://mudisa.com.sv/'},
  { alt: 'safety',  img: safety,  url:'https://www.generalsafety.com.sv/'},
  { alt: 'jacobs',  img: jacobs,  url:'https://www.grupojacobs.com/'},
  { alt: 'aso',     img: aso,     url:'https://aso.com.sv/'},
  { alt: 'esna',    img: esna,    url:'https://www.esnavillages.org/'},
  { alt: 'educo',   img: educo,   url:'https://educo.org.sv/'},
  { alt: 'gs1',     img: gs1,     url:'https://www.gs1sv.org/'},
  { alt: 'briee',   img: briee,   url:'http://briee.net'},
  { alt: 'luissa',  img: luissa, url:'https://luissa.com.sv/'},//luissa
  { alt: 'llaollao',img: llaollao,url:'https://llaollaosv.com/'},
  { alt: 'sites',   img: sites,   url:'https://www.linkedin.com/company/sites-s-a-de-c-v/'},
  { alt: 'smurfit', img: smurfit, url:'https://www.smurfitkappa.com/sv'},
  { alt: 'subway',  img: subway,  url:'https://www.subway.com/es-SV'},
  { alt: 'aeinfo',  img: aeinfo,  url:'https://aeinformatica.net'},
  { alt: 'lacasita',img: lacasita,url:'https://www.facebook.com/LaCasitaFineFurniture/'}
]

export default function Clientes({cont}){
  // const[actualCliente, setActualCliente] = useState(0);
  // const [carousel, setCarousel] = useState(null)

  useEffect(()=>{
    let car = M.Carousel.init(document.querySelectorAll('.carousel'),{
        numVisible:3,
        shift:150,
        duration:300
      })
    setInterval(()=>{
      car[0].next()
    },3000)
  },[])

  // useEffect(()=>{
  //   let car = M.Carousel.init(document.querySelectorAll('.carousel'),{
  //     numVisible:3,
  //     shift:150,
  //     duration:500
  //   })
  //   setCarousel(car[0])
  //   setTimeout(()=>{
  //     setActualCliente(1);
  //     car[0].next()
  //   },5000)
  // },[])

  // useEffect(()=>{
  //   if(carousel !== null)
  //     setTimeout(()=>{
  //         carousel.next()
  //         setActualCliente(actualCliente+1)
  //     },5000)
  // },[actualCliente])

  return(
    <div className="container">
      <div className="row clientes-h-fix">
        <div className="col m4 h-100 valign-wrapper hide-on-small-only">
          <img src={LogoA} alt="audicca" className="responsive-img"/>
        </div>
        <div className="col s12 m8">
          <div className="row">
            <div className="col s6 h-100 valing-wrapper">
              <div className="center-align">
                <p><i style={{fontSize:'2.5rem'}} className="material-icons">business_center</i></p>
                <h6>{cont[0]}</h6>
                <h4>75</h4>
              </div>
            </div>
            <div className="col s6 h-100 valing-wrapper">
              <div className="center-align">
                <p><i style={{fontSize:'2.5rem'}} className="material-icons">business</i></p>
                <h6>{cont[1]}</h6>
                <h4>16</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h3>{cont[2]}:</h3>
      <div className="carousel">
        {empresas.map((e,i)=>{
          return (
            <a className="carousel-item white" href={e.url} key={i} style={{width:400}}>
              <img className="responsive-img" src={e.img} alt={e.alt} />
            </a>
          )
        })}
      </div>
      {/* <div className="row">
        { empresas.map((e,i)=>{
            return <ClienteCard key={"cliente"+i} info={e} n={i} t={empresas.length}/>
          }) }
      </div> */}
    </div>
  )
}

/*
function ClienteCard(props){
  var info = props.info
  var n = props.n + 1
  var t = props.t;
  var extL = t%4, extM = t%3, extS = t%2
  var limL = t - extL, limM = t - extM, limS = t - extS
  var clase = 'clienteLogo valign-wrapper hoverable col s6 m4 l3 ';

  if(n > limL && n-limL === 1){
    if(extL === 1) clase += 'offset-l4 '
    if(extL === 2) clase += 'offset-l3 '
    if(extL === 3) clase += 'offset-l1 '
  }
  if(n > limM && n-limM === 1){
    if(extM === 1) clase += 'offset-m4 '
    if(extM === 2) clase += 'offset-m2'
  }
  if(n > limS && n-limS === 1){
    if(extS === 1) clase += 'offset-s3 '
  }
  return(
    <div className={clase} key={n}>
      <a href={info.url} target="_blank" rel="noreferrer">
        <img className='responsive-img' src={info.img} alt={info.alt}/>
      </a>
    </div>
  )
}*/