import React, {useEffect} from 'react'
import M from 'materialize-css'
import B1 from '../img/grupal1.jpg'
import B2 from '../img/grupal2.jpg'
import B3 from '../img/grupal3.jpg'


export default function BannerExp(props) {
  useEffect(()=>{
    let slider = document.querySelectorAll('.slider')
    M.Slider.init(slider,{
      indicators:false,
      interval:8000,
      duration:1000,
      height: 450
    })
  },[])
  
  let banner = [
    { img:B1, h2:props.cont, divClass:"caption center-align" },
    { img:B2, h2:props.cont, divClass:"caption left-align" },
    { img:B3, h2:props.cont, divClass:"caption right-align" },
  ]

  return (
    <div className="slider">
      <ul className="slides">
        {banner.map((e,i)=>{
          return(
            <li key={i}>
              <img src={e.img} alt="banner 1"/>
              <div className="caption center-align"  style={{backgroundColor:"rgba(0,0,0,0.5)"}}>
                <div className="row">
                  <div className="col s10 offset-s1 m6 offset-m3">
                    <h2 className="header">{e.h2}</h2>
                    <div className="divider"></div>
                    <p>Auditoría Integral y Consultoría, S.A. de C.V.</p>
                  </div>
                </div>
                
                {/* <button className="btn-large main-color-1" onClick={()=>props.switch(e.dest)}>{e.btn}</button> */}
              </div>
            </li>
          )
        })}
      </ul>
    </div>
  );
}