import React, {useEffect} from 'react'
import M from 'materialize-css'
import LogoA from './img/logoA.png'
import TabsExpertos from './components/tabsExpertos'
import BannerExp from './components/bannerExp'

export default function Expertos({cont}){
  useEffect(()=>{
    M.Parallax.init(document.querySelectorAll('.parallax')) 
    let car = M.Carousel.init(document.getElementById('carouselExp'),{
      indicators: true
    })
    setInterval(()=>car.next(),5000)
  },[])
  return(
    <>
    <BannerExp cont={cont[0]} />
    <div className="container">
      <div className="row">
        <div className="col s12">
          <div className="section">
            <h6 className="center-align">{cont[1]}</h6>
            <div className="divider main-color-4"></div>
          </div>
        </div>
        <div className="col s12 m4 hide-on-small-only">
          <img src={LogoA} alt="Audicca" className="responsive-img"/>
        </div>
        <div className="col s12 m8 center-align">
          <h5>{cont[2]}</h5>
          <blockquote style={{textAlign:'justify'}}>{cont[3]}</blockquote>
        </div>
      </div>
    </div>
    <div className="col s12 m8 center-align">
      <h2>Quienes somos</h2>
    </div>
    <TabsExpertos cont={cont}/>
    <div className="container">
      <div className="row">
        <div className="col s12 m5">
          <h5>{cont[8]}</h5>
          <div className="divider main-color-1"></div>
          <p style={{textAlign:'justify'}}>{cont[9]}</p>
          <p style={{textAlign:'justify'}}>{cont[10]}</p>
        </div>
        <div className="col s12 m7">
          <div id="carouselExp" className="carousel carousel-slider">
            {/* Mision */}
            <div className="carousel-item main-color-1">
              <div className="container center section">
                <h3>{cont[11]}</h3>
                <div className="divider main-color-3 light"></div>
                <div className="section">
                  <p style={{textAlign:'justify'}}>{cont[12]}</p>
                </div>
              </div>
            </div>
            {/* Vision */}
            <div className="carousel-item main-color-1">
              <div className="container center section">
                <h3>{cont[13]}</h3>
                <div className="divider main-color-3 light"></div>
                <div className="section">
                  <p style={{textAlign:'justify'}}>{cont[14]}</p>
                </div>
              </div>
            </div>
            {/* Valores */}
            <div className="carousel-item main-color-1">
              <div className="container center section">
                <h3>{cont[15]}</h3>
                <div className="divider main-color-3 light"></div>
                <div className="section">
                  <ul>
                    {cont[16].map((e,i)=>{
                      return <li key={i} className="valign-wrapper"><i className="material-icons">chevron_right</i> {e}</li>
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}