import React from 'react'
import ept from '../img/ept.png'

export default function Ept({cont}){
  return(
    <>
    <div className="row">
      <div className="col s12">
        <h4> {cont.h} </h4>
        <div className="divider main-color-4"></div>
      </div>
      <div className="col l4 hide-on-med-and-down h-100 valing-wrapper">
        <img src={ept} alt="EPT" className="responsive-img"/>
      </div>
      <div className="col s10 offset-s1 l8">
        <p style={{textAlign:'justify'}}>{cont.t}</p>
      </div>
    </div>
    </>
  )
}