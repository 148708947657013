import React,{useEffect, useState} from 'react'
import Banner from './banner1.png'
import M from 'materialize-css'
import contacto from './contacto.png'

export default function Contactos({cont}){
  
  const [nombre, setNombre] = useState('');
  const [correo, setCorreo] = useState('');
  const [tel, setTel] = useState('');
  const [empr, setEmpr] = useState('');
  const [msg, setMsg] = useState('');
  const [send, setSend] = useState(false);
  const [load, setLoad] = useState(false);

  useEffect(()=>{
    let para = document.querySelectorAll('.parallax')
    M.Parallax.init(para)
  })

  useEffect(()=>{
    if(nombre.length > 5 &&
      correo.length > 10 &&
      /^\w+@\w+\.\w{2,3}(\.\w{2,3})*$/.test(correo) &&
      tel.length > 7 &&
      /^(\(?\+\d{1,3}\)?(\s)?)?\d+((-| )?\d+)*$/.test(tel) &&
      empr.length > 5)
      setSend(true)
    else setSend(false)
  },[nombre,correo,tel,empr])

  let sendMail = e =>{
    e.preventDefault()
    setLoad(true)
    let form = new FormData()
    form.append("name", nombre)
    form.append("mail", correo)
    form.append("empr", empr)
    form.append("tel", tel)
    form.append("msg", msg)

    fetch("http://localhost/ofi/audicca/sendMail.php",{
    // fetch("https://audicca.com/back/sendMail.php",{
      method:"POST",
      body:form
    })
    .then(r=>r.json())
    .then(r=>{
      if(r.r){
        setNombre('')
        setCorreo('')
        setEmpr('')
        setTel('')
        setMsg('')
        setLoad(false)
        M.toast({html:"Su correo ha sido enviado!", inDuration:500, outDuration:500, displayLength:5000})
      }
    })
    
  }

  return(
    <>
    <div className="parallax-container" style={{height:'16em'}}>
      <div className="parallax">
        <img src={Banner} alt="Banner"/>
      </div>
      <div className="container left-align white-text" style={{padding:'0 1% 1% 1%'}}>
          <h2 className="header hide-on-small-only">{cont[0]}</h2>
          <h3 className="header hide-on-med-and-up">{cont[0]}</h3>
          <p>{cont[1]}<br/>{cont[2]}</p>
      </div>
    </div>
    <div className="container">
      <div className="row">
        <div className="col s12">
          <h3>Conocenos</h3>
          <div className="section">
            <div className="divider main-color-4"></div>
          </div>
        </div>
        <div className="col s12 m7 xl8">
          <iframe  width="100%" height="440px" id="mapa" title="mapa" src="https://maps.google.com/maps?q=AUDICCA%2C%20San%20Salvador&t=&z=19&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
        </div>
        <div className="col s12 m5 xl4">
          <div className="card">
            <div className="card-content ">
              <span className="card-title">{cont[3]}</span>
              <p>{cont[4]} La Floresta #35, San Salvador. El Salvador.</p>
            </div>
          </div>
          <div className="row">
            <div className="col s12">
              <div className="card">
                <div className="card-content ">
                  <span className="card-title">{cont[5]}</span>
                  <p>(+503) 2273-0706</p>
                  <p>info@audicca.com</p>
                </div>
              </div>
            </div>
            <div className="col s12">
              <div className="card">
                <div className="card-content ">
                  <span className="card-title">{cont[6]}</span>
                  <p>{cont[7]}: 8:00 a.m. - 5:00 p.m.</p>
                  <p>{cont[8]}: 8:00 a.m. - 12:00 p.m.</p>
                </div>
              </div>
            </div> 
          </div>
        </div>
        <div className="col s12">
          <h3>{cont[9]}</h3>
          <div className="section">
            <div className="divider main-color-4"></div>
          </div>
        </div>
        <div className="col m5 xl4 hide-on-small-only">
          <img src={contacto} alt="Contacto" className="responsive-img" style={{minHeight:'322.25px',maxHeight:'369.25px'}}/>
        </div>
        <div className="col s10 offset-s1 m7 xl8 z-depth-1">
          <div className="container">
            <div className="section"></div>
            <form className="row" style={{position:'relative'}}>

              {load?<div className="loader">
                <div className="preloader-wrapper small active">
                  <div className="spinner-layer spinner-main-only">
                    <div className="circle-clipper left">
                      <div className="circle"></div>
                    </div>
                    <div className="gap-patch">
                      <div className="circle"></div>
                    </div>
                    <div className="circle-clipper right">
                      <div className="circle"></div>
                    </div>
                  </div>
                </div>
              </div>:''}

              <div className="input-field col s12 m6">
                <i className="material-icons prefix main-text">account_circle</i>
                <input type="text" value={nombre} onChange={e=>setNombre(e.target.value)}/>
                <label htmlFor="input_nombre">{cont[10]}*</label>
              </div>
              <div className="input-field col s12 m6">
                <i className="material-icons prefix main-text">alternate_email</i>
                <input type="email" value={correo} onChange={e=>setCorreo(e.target.value)}/>
                <label htmlFor="input_correo">{cont[11]}*</label>
              </div>
              <div className="input-field col s12 m6">
                <i className="material-icons prefix main-text">business</i>
                <input type="text" value={empr} onChange={e=>setEmpr(e.target.value)}/>
                <label htmlFor="input_empresa">{cont[12]}*</label>
              </div>
              <div className="input-field col s12 m6">
                <i className="material-icons prefix main-text">phone</i>
                <input type="text" value={tel} onChange={e=>setTel(e.target.value)}/>
                <label htmlFor="input_telefono">{cont[13]}*</label>
              </div>
              <div className="input-field col s12">
                <textarea className="materialize-textarea" value={msg} onChange={e=>setMsg(e.target.value)}/>
                <label htmlFor="mensaje">{cont[14]}</label>
              </div>
              <div className="input-field col s12">
                <span>*{cont[15]}</span>
                <button className={send?"btn main-color-1 right":"btn main-color-1 right disabled"} onClick={e=>sendMail(e)}>
                  <i className="material-icons left">send</i>
                  {cont[16]}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
