import React, {useEffect} from 'react'
import M from 'materialize-css'
import B1 from './img/banner1.jpg'
import B2 from './img/banner4.jpg'
import B3 from './img/banner3.jpg'
import B4 from './img/banner2.jpg'


export default function Banner(props) {
  useEffect(()=>{
    let slider = document.querySelectorAll('.slider')
    M.Slider.init(slider,{
      indicators:false,
      interval:8000,
      duration:1000,
      height: 600
    })
  },[])
  
  let banner = [
    { img:B2, h4:props.cont[0], btn: props.cont[3] },
    { img:B1, h4:props.cont[2], btn: props.cont[1], divClass:"caption center-align", dest:2 },
    { img:B3, h4:props.cont[4], btn: props.cont[3], divClass:"caption center-align", dest:3 },
    { img:B4, h4:props.cont[2], btn: props.cont[1], divClass:"caption left-align", dest:2 }
  ]

  return (
    <div className="slider">
      <ul className="slides">
        {banner.map((e,i)=>{
          return(
            <li key={i}>
              <img src={e.img} alt="banner 1"/>
              {e.divClass?
              <div className={e.divClass} style={{backgroundColor:"rgba(0,0,0,0.5)"}}>
                <div className="row">
                  <div className="col s10 offset-s1 m8 offset-m2">
                    <h2 className="header">AUDICCA</h2>
                    {/* <div className="divider"></div> */}
                    <h4>{e.h4}</h4>
                    <p>Auditoría Integral y Consultoría, S.A. de C.V.</p>
                    <div className="divider"></div><br/>
                    <button className="btn-large main-color-1" onClick={()=>props.switch(e.dest)}>{e.btn}</button>
                  </div>
                </div>
              </div>
              :""}
            </li>
          )
        })}
      </ul>
    </div>
  );
}