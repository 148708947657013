// import M from "materialize-css"

export default function MatCard(props){
  return (
      <div className=" section">
        <div className="hide-on-med-and-down">
          <div className="card">
            <div className="card-image waves-effect waves-block waves-light">
              <img className="activator" alt={props.nombre} src={props.img} width="100"/>
            </div>
            <div className="card-content main-color-1">
              <span className="card-title activator">{props.nombre}{props.reveal?<i className="material-icons right">more_vert</i>:""}</span>
              <span className="blue-grey-text text-lighten-3">{props.span}</span>
            </div>
            {props.reveal?
            <div className="card-reveal main-color-1">
              <span className="card-title" style={{fontSize:"2rem"}}>{props.nombre} <i className="material-icons right">close</i></span>
              <p style={{textAlign:'justify',fontSize:"1.25rem"}}>{props.p}</p>
              <span className="blue-grey-text text-lighten-3">{props.mail}</span>
            </div>:""}
          </div>
        </div>
        <div className="hide-on-large-only">
          <div className="card horizontal">
            <div className="card-image waves-effect waves-block waves-light">
              <img className="activator" alt={props.nombre} src={props.img} width="100"/>
            </div>
            <div className="card-content main-color-1">
              <span className="card-title activator">{props.nombre}{props.reveal?<i className="material-icons right">more_vert</i>:""}</span>
              <span className="blue-grey-text text-lighten-3">{props.span}</span>
            </div>
            {props.reveal?
            <div className="card-reveal main-color-1">
              <span className="card-title">{props.nombre} <i className="material-icons right">close</i></span>
              <p style={{textAlign:'justify'}}>{props.p}</p>
              <span className="blue-grey-text text-lighten-3">{props.mail}</span>
            </div>:""}
          </div>
        </div>
      </div>
  )
}