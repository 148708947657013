import React from 'react'
import Out from '../img/out.png'

export default function Outsource({cont}){
  return(
    <>
    <div className="row">
      <div className="col s12">
        <h4 > Outsourcing </h4>
        <div className="divider main-color-4"></div>
        <p style={{textAlign:'justify'}}>{cont.t}</p>
      </div>
      <div className="col l4 hide-on-med-and-down h-100 valign-wrapper">
        <img src={Out} alt="outsourcing" className="responsive-img" style={{height:'182px'}}/>
      </div>
      <div className="col s10 offset-s1 l8">
        <ul>
          {cont.l.map((e,i)=>{
            return <li key={i} className="valign-wrapper"><i className="material-icons">chevron_right</i>{e}</li>
          })}
        </ul>
      </div>
    </div>
    </>
  )
}