import React, {useEffect} from 'react'
import LogoB from './LogoB.png'
import M from 'materialize-css'

export default function Header(props){

  const icons = ["home","business_center","people","perm_contact_calendar","dashboard"]

  useEffect(()=>{
    M.Sidenav.init(document.querySelectorAll('.sidenav'))
  })
  
  // const Eng = e =>{
  //   e.preventDefault()
  //   props.lang[1](props.lang[0]==='es'?"en":'es')
  // }

  const redir = (e,f,p) => {
    e.preventDefault()
    f(p)
  }

  return(
    <>
    <div className="navbar-fixed">
      <nav className="main-color-1">
        <div className="nav-wrapper container">
          <a href="inicio" onClick={e=>redir(e,props.switch,0)}>
            <img src={LogoB} className="brand-logo responsive-img" alt="AUDICCA" style={{paddingTop: "10px"}}/>
          </a>
          <a href="menu" data-target="mobile-nav" className="sidenav-trigger "><i className="material-icons">menu</i></a>
          {/* <a className="right hide-on-med-and-up" onClick={e=>Eng(e)} href={props.lang[0]==='es'?"en":'es'}>{props.lang[0]}<i className="material-icons right ml-0">translate</i></a> */}
          <ul id="nav-mobile" className="right hide-on-small-only">
            {props.cont.map((e,i)=>{
              if(e==="Iniciar sesión")return <li key={i}><a href="https://powerbi.audicca.com" target="_ablank">{e}</a></li>
              return <li key={i} className={props.page===i?"active":''}><a onClick={e=>redir(e,props.switch,i)} href={e}>{e}</a></li>
            })}
            {/* <li><a href={props.lang[0]==='es'?"en":'es'} onClick={e=>Eng(e)}>
              {props.lang[0]}
              <i className="material-icons right ml-0">translate</i>
            </a></li> */}
          </ul>
        </div>
      </nav>
    </div>

    <ul className="sidenav main-color-1" id="mobile-nav">
      <li>
        <div className="section">
          <div className="container">
            <h4>
              AUDICCA
              <a href="menu" onClick={e=>e.preventDefault()}><i className="sidenav-close material-icons white-text right">close</i></a>
            </h4>
            <div className="divider"></div>
            <div className="section"></div>
          </div>
        </div>
      </li>
      {props.cont.map((e,i)=>{
        if(i===4)return <li key={i}><a href="https://powerbi.audicca.com" target="_ablank" className="white-text "><i className="material-icons white-text">{icons[i]}</i>{e}</a></li>
        return <li key={i} className={props.page===i?"active":''}><a onClick={e=>redir(e,props.switch,i)} href={e} className="white-text"><i className="material-icons white-text">{icons[i]}</i>{e}</a></li>
      })}
    </ul>
  </>
  )
}
