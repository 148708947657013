import React/* , { useEffect } */ from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App.jsx';
// import M from "materialize-css"

ReactDOM.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
  document.getElementById('root')
);

/*function Test(){
  useEffect(()=>{
    M.Sidenav.init(document.querySelectorAll('.sidenav'))
    M.Dropdown.init(document.querySelectorAll('.dropdown-trigger'))
    M.Collapsible.init(document.querySelectorAll('.collapsible'))
  })
  return<>
  <ul id="slide-out" class="sidenav">
  <li><a href="#!">First Sidebar Link</a></li>
  <li><a href="#!">Second Sidebar Link</a></li>
  <li class="no-padding">
    <ul class="collapsible collapsible-accordion">
      <li>
        <a class="collapsible-header">Dropdown<i class="material-icons">arrow_drop_down</i></a>
        <div class="collapsible-body">
          <ul>
            <li><a href="#!">First</a></li>
            <li><a href="#!">Second</a></li>
            <li><a href="#!">Third</a></li>
            <li><a href="#!">Fourth</a></li>
          </ul>
        </div>
      </li>
    </ul>
  </li>
</ul>
<ul class="right hide-on-med-and-down">
  <li><a href="#!">First Sidebar Link</a></li>
  <li><a href="#!">Second Sidebar Link</a></li>
  <li><a class="dropdown-trigger" href="#!" data-target="dropdown1">Dropdown<i class="material-icons right">arrow_drop_down</i></a></li>
  <ul id='dropdown1' class='dropdown-content'>
    <li><a href="#!">First</a></li>
    <li><a href="#!">Second</a></li>
    <li><a href="#!">Third</a></li>
    <li><a href="#!">Fourth</a></li>
  </ul>
</ul>
<a href="#" data-target="slide-out" class="sidenav-trigger"><i class="material-icons">menu</i></a>
   </>
}*/
