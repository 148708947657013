import M from 'materialize-css'
import React,{useEffect} from 'react'
import Banner from './img/banner.jpg'
import Auditoria from './comp/audit'
import Consultoria from './comp/consultoria'
import Ept from './comp/ept'
import Legal from './comp/legal'
import Outsource from './comp/outsource'

export default function Servicios({cont}){
  useEffect(()=>{
    M.Parallax.init(document.querySelectorAll('.parallax'))
    M.Tabs.init(document.getElementById('tabs-servicios'),{
      swipeable:true
    })
    document.querySelectorAll(".tabs-content.carousel.carousel-slider")[0].style=""
    document.getElementById("consultoria").style.position="relative";
    M.Modal.init(document.querySelectorAll(".modal"),{endingTop:"4%"})
  },[])
  
  const serv =[
    { icon:'assessment', des:'consultoria',mainText:'Consultoría' },
    { icon:'find_in_page', des:'auditoria',mainText:'Auditorías Assurance' },
    { icon:'gavel', des:'taxlegal',mainText:'Tax and Legal' },
    { icon:'supervisor_account', des:'outsourcing',mainText:'Outsourcing' },
    { icon:'attach_money', des:'ept',mainText:'EPT' },
  ]

  return(
    <>
    <div className="parallax-container" style={{height:'16em'}}>
      <div className="parallax">
        <img src={Banner} alt="Banner"/>
      </div>
      <div className="container left-align white-text" >
        <h2 className="header">{cont[0]}</h2>
        <p>Auditoría Integral y Consultoría, S.A. de C.V.</p>
      </div>
    </div>

    <div className="section">
      <div className="row">
        <div className="hide-on-med-and-up">
          {serv.map((e,i)=>{
            return(
              <button key={i} className="waves-effect wabes-audicca btn-flat btn-large modal-trigger col s10 offset-s1" data-target={e.des+"-modal"}>
                <i className="material-icons left">{e.icon}</i>
                {e.mainText}
              </button>
              )
            })
          }
          <div id="consultoria-modal" className="modal">
            <div className="modal-content">
              <Consultoria cont={cont[4]}/>
            </div>
          </div>
          <div id="auditoria-modal" className="modal">
            <div className="modal-content">
              <Auditoria cont={cont[1]}/>
            </div>
          </div>
          <div id="taxlegal-modal" className="modal">
            <div className="modal-content">
              <Legal cont={cont[2]}/>
            </div>
          </div>
          <div id="outsourcing-modal" className="modal">
            <div className="modal-content">
              <Outsource cont={cont[3]}/>
            </div>
          </div>
          <div id="ept-modal" className="modal">
            <div className="modal-content">
              <Ept cont={cont[5]}/>
            </div>
          </div>
        </div>

        <div className="col s10 offset-s1 hide-on-small-only">
          <ul id="tabs-servicios" className="tabs center-align">
            {serv.map((e,i)=>{
                return(
                <li key={i} className="tab" >
                  <a href={"#"+e.des} className="">
                    <div className="valign-wrapper">
                      <i className="material-icons left">{e.icon}</i>
                      {e.mainText}
                    </div>
                  </a>
                </li>
                )
              })
            }
          </ul>
          <div className="tabs-content">
            <div id="consultoria">
              <Consultoria cont={cont[4]}/>
            </div>
            <div id="auditoria">
              <Auditoria cont={cont[1]}/>
            </div>
            <div id="taxlegal">
              <Legal cont={cont[2]}/>
            </div>
            <div id="outsourcing">
              <Outsource cont={cont[3]}/>
            </div>
            <div id="ept">
              <Ept cont={cont[5]}/>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}