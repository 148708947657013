import React from 'react'
import fb from './facebook.png'
import lin from './linkedin.png' 

export default function Footer({cont}){
  return(
    <footer className="page-footer main-color-4 dark">
        <div className="row">
          <div className="col s10 offset-s1 m4 offset-m1">
            <h6>AUDICCA</h6> 
            <div className="divider main-color-3 light"></div>
            <p>{cont[0]}</p>
          </div>
          <div className="col s10 offset-s1 m4 offset-m2 ">
            <div className="">
              <h6>{cont[1]}</h6>
                <div className="divider main-color-3 light"></div>
                <ul>
                  <li style={{padding:'3px 0'}} className="white-text valign-wrapper"><i style={{marginRight:'10px'}} className="material-icons">phone</i> (+503)2273-0706</li>
                  <li style={{padding:'3px 0'}} className="white-text valign-wrapper"><i style={{marginRight:'10px'}} className="material-icons">mail_outline</i> info@audicca.com</li>
                  <li style={{padding:'3px 0'}}><a className="white-text valign-wrapper" href="https://bit.ly/319Xpsf" target="_ablank"><img style={{marginRight:'10px'}} src={fb} className="responsive-img" alt=""/> Facebook</a></li>
                  <li style={{padding:'3px 0'}}><a className="white-text valign-wrapper" href="https://www.linkedin.com/company/audiccasv/" target="_ablank"><img style={{marginRight:'10px'}} src={lin} className="responsive-img" alt=""/> Linkedin</a></li>
                </ul>
            </div>
        </div>
      </div>
      <div className="footer-copyright white-text">
        {cont[2]}
        <span className='right'>{cont[3]}<a href="aeinformatica.net" className="white-text">AEInformatica</a></span>
      </div>
    </footer>
  )
}