import { useEffect } from "react"
import M from "materialize-css"
import kevinImg from "../img/kevin.jpg"
import moisesImg from "../img/moises.jpg"
import fatimaImg from "../img/fatima.jpg"
import keniaImg from "../img/kenia.jpg"
import ofeliaImg from "../img/ofelia.jpg"
//import edgarImg from "../img/edgar.jpg"
import victorImg from "../img/victor.jpg"
import mauricioImg from "../img/mauricio.jpg"
import danielImg from "../img/daniel.jpg"
import gerardoImg from "../img/gerardo.jpg"
import enriqueImg from "../img/enrique.jpg"
import MatCard from "./cards"


export default function TabsExpertos(props){

  useEffect(()=>{
    M.Tabs.init(document.getElementById("tabs-expertos"),{
      swipeable:true
    })
    document.getElementById("gerencia").style.position = "relative"
    document.querySelectorAll(".tabs-content.carousel.carousel-slider")[0].style=""
  },[])
  return(<>    
    <ul id="tabs-expertos" className="tabs center-align">
      <li className="tab" ><a href="#gerencia" className="active">Socios</a></li>
      <li className="tab"><a className="" href="#conta">SENIORS</a></li>
      <li className="tab"><a href="#contajr">JUNIORS</a></li>
      <li className="tab"><a href="#IT">IT</a></li>
    </ul>
    <div className="tabs-content" >
      <div id="gerencia">
        <div className="container">
          <div className="section row">
            <div className="col s12 m10 offset-m1 l4">
              <MatCard reveal={true} img={kevinImg} nombre="Kevin A. Serrano" span={props.cont[6]} p={props.cont[7]} mail="kevin.serrano@audicca.com"/>
            </div>
            <div className="col s12 m10 offset-m1 l4">
              <MatCard reveal={true} img={victorImg} nombre="Víctor A. Guzman" span={props.cont[4]} p={props.cont[5]} mail="victor.guzman@audicca.com"/>
            </div>
            <div className="col s12 m10 offset-m1 l4">
              <MatCard reveal={true} img={mauricioImg} nombre="Mauricio A. Rivera" span={props.cont[17]} p={props.cont[18]} mail="mauricio.rivera@audicca.com"/>
            </div>
          </div>
        </div>
      </div>
      <div id="conta">
        <div className="container">
          <div className="section row">
            <div className="col s12 m10 offset-m1 l4 offset-l1">
              <MatCard img={moisesImg} nombre="Moises O. Sanabria" span="Contador Senior" />
            </div>
            <div className="col s12 m10 offset-m1 l4 offset-l2">
              <MatCard img={fatimaImg} nombre="Fatima Guzman" span="Contador Senior" />
            </div>
            {/* <div className="col s12 m10 offset-m1 l4">
              <MatCard img={edgarImg} nombre="Edgar I. Flores" span="Contador Senior"/>
            </div> */}
          </div>
        </div>
      </div>
      <div id="contajr">
        <div className="container">
          <div className="section row">
            <div className="col s12 m10 offset-m1 l4 offset-l1">
              <MatCard img={ofeliaImg} nombre="Ofelia G. Hernandez" span="Contador Junior"/>
            </div>
            <div className="col s12 m10 offset-m1 l4 offset-l2">  
              <MatCard img={keniaImg} nombre="Kenia A. Chinchilla" span="Contador Junior" />
            </div>
          </div>
        </div>
      </div>
      <div id="IT">
        <div className="container">
          <div className="section row">
            <div className="col s12 m10 offset-m1 l4">
              <MatCard img={enriqueImg} nombre="C. Enrique Castro" span="Soporte Tecnito"/>
            </div>
            <div className="col s12 m10 offset-m1 l4">
              <MatCard img={gerardoImg} nombre="Gerardo J. Lopez" span="Desarrollador Web"/>
            </div>
            <div className="col s12 m10 offset-m1 l4">
              <MatCard img={danielImg} nombre="Daniel J. Cartagena" span="Mantenimiento Informático"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>)
}